<template>
  <div class="participant-record-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Participant' }"
        >會員管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>活動報名資料</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          會員報名紀錄 <span class="unit">數量：{{ total }}</span>
        </p>
      </table-title>

      <el-form class="search-form" inline>
        <el-form-item label="活動名稱">
          <el-autocomplete
            v-model="setup.search"
            size="small"
            :fetch-suggestions="handleRemoteSearchActivityName"
            style="width: 200px"
            @keydown.native.enter="(e) => e.preventDefault()"
          >
          </el-autocomplete>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleSearch" type="primary" size="small"
            >搜尋</el-button
          >
          <el-button type="success" size="small" @click="handleExport"
            >匯出</el-button
          >
        </el-form-item>
      </el-form>

      <el-table :data="participant_record_data">
        <el-table-column
          label="出席"
          fixed="left"
          prop="attend"
          width="80"
          sortable
        >
          <template v-slot="{ row }">
            <el-checkbox
              :value="row.attend"
              @change="handleAttend(row.participant_record_id)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          prop="participant_record_id"
          label="ID"
          fixed="left"
          width="100"
          sortable
        ></el-table-column>
        <el-table-column
          prop="participant.name"
          label="會員名稱"
          sortable
          width="240"
        >
        </el-table-column>
        <el-table-column
          prop="participant.username"
          label="會員信箱"
          width="240"
          sortable
        ></el-table-column>
        <el-table-column
          prop="activity.title_zh"
          label="活動名稱"
          width="240"
          sortable
        ></el-table-column>
        <el-table-column
          label="創建時間"
          prop="created_at"
          width="200"
          sortable
        >
          <template v-slot="{ row }">
            {{ $formatTime(row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column width="360" fixed="right" label="編輯">
          <template slot-scope="{ row }">
            <el-button
              size="mini"
              type="primary"
              @click="
                handleRedirect(
                  'DetailParticipantRecord',
                  row.participant_record_id
                )
              "
              icon="el-icon-tickets"
              >內容</el-button
            >
            <el-button
              size="mini"
              type="warning"
              @click="
                handleRedirect(
                  'EditParticipantRecord',
                  row.participant_record_id
                )
              "
              icon="el-icon-edit"
              >編輯
            </el-button>
            <el-button
              size="mini"
              class="purple-button"
              icon="el-icon-position"
              @click="handleSendMail(row.participant_record_id)"
              >寄信
            </el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleRemove(row.participant_record_id)"
              >刪除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import {
  getParticipantsPagination,
  updateParticipantRecord,
  removeParticipantRecord,
  sendEmail,
  exportParticipateRecord,
} from "@/api/participant-record";
import { getActivities } from "@/api/activity";
import { Loading } from "element-ui";
import { saveAs } from "file-saver";

export default {
  name: "ParticipantRecord",
  data() {
    return {
      participant_record_data: [],
      total: 0,
      setup: {
        page: 0,
        limit: 25,
        search: "",
      },
      activities: [],
    };
  },
  computed: {
    canExport() {
      if (this.activities.length === 0) return false;

      if (this.activities.includes(this.setup.search)) return true;

      return false;
    },
  },
  created() {
    this.handleGetParticipantRecord();
  },
  methods: {
    async handleGetParticipantRecord() {
      const { results, total } = await getParticipantsPagination(this.setup);

      this.participant_record_data = results;
      this.total = total;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetParticipantRecord();
    },
    async handleSearch() {
      this.setup.page = 0;

      let query = {
        p: this.setup.page,
        s: encodeURIComponent(this.setup.search),
      };

      if (decodeURIComponent(query.s).length === 0) delete query.s;

      this.$router.push({ query });

      await this.handleGetParticipantRecord();
    },
    async handleRemoteSearchActivityName(queryString, cb) {
      const activities = await getActivities({
        search: queryString,
      });
      this.activities = [
        ...new Set(activities.map(({ title_zh }) => title_zh)),
      ];
      cb(this.activities.map((title_zh) => ({ value: title_zh })));
    },
    async handleExport() {
      if (!this.canExport) {
        this.$message.error("請輸入正確的活動名稱，活動名稱須完全匹配");
        return;
      }

      const res = await exportParticipateRecord({
        activity_name: encodeURIComponent(this.setup.search),
      });

      saveAs(res, `${this.setup.search}.csv`);
    },
    async handleAttend(participantRecordId) {
      try {
        const index = this.participant_record_data.findIndex(
          ({ participant_record_id }) =>
            participant_record_id === participantRecordId
        );

        await updateParticipantRecord(participantRecordId, {
          attend: !this.participant_record_data[index].attend,
        });

        this.participant_record_data[index].attend =
          !this.participant_record_data[index].attend;

        this.$message({
          type: "success",
          message: "編輯成功",
        });
      } catch (error) {
        console.log(error);
        this.$message.error("修改失敗");
      }
    },
    handleRemove(participantRecordId) {
      this.$confirm("是否刪除該會員紀錄", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await removeParticipantRecord(+participantRecordId);
          this.handleGetParticipantRecord();

          this.$message({
            type: "success",
            message: "刪除成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
    handleSendMail(participantRecordId) {
      this.$confirm("是否再次寄送信件", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const loading = Loading.service({
            fullscreen: true,
            background: "rgba(0, 0, 0, 0.8)",
            text: "寄送中",
            spinner: "el-icon-loading",
          });
          try {
            await sendEmail(participantRecordId);

            this.$message({
              type: "success",
              message: "寄送成功",
            });
          } catch (error) {
            this.$message({
              type: "info",
              message: "寄送取消",
            });
          } finally {
            loading.close();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "寄送取消",
          });
        });
    },
    async handleRedirect(name, id) {
      await this.$router.push({ name, params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.participant-record-block {
  .search-form {
    background-color: #f2f2f3;
    padding: 20px 20px 0 20px;
    margin-bottom: 20px;
  }
}
</style>
