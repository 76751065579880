import request from "@/utils/request";

/**
 * Get participant record.
 *
 * @param {number} participant_record_id
 */
export function getParticipantRecord(participant_record_id) {
  return request({
    url: `api/v1/participants-records/${participant_record_id}`,
    method: "GET",
  });
}

/**
 * Get participant records with pagination and search features.
 *
 * @param {object} params
 * @param {number} [params.limit]
 * @param {number} [params.page]
 * @param {string} [params.search]
 */
export function getParticipantsPagination(params) {
  return request({
    url: "api/v1/participants-records/page",
    method: "GET",
    params,
  });
}

/**
 * Update participant record.
 *
 * @param {number} participant_record_id
 * @param {object} data
 * @param {boolean} [data.attend]
 * @param {string} [data.answer_1]
 * @param {string} [data.answer_2]
 * @param {string} [data.answer_3]
 */
export function updateParticipantRecord(participant_record_id, data) {
  return request({
    url: `api/v1/participants-records/${participant_record_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Remove the participant record.
 *
 * @param {number} participant_record_id
 */
export function removeParticipantRecord(participant_record_id) {
  return request({
    url: `api/v1/participants-records/${participant_record_id}`,
    method: "DELETE",
  });
}

/**
 * Send email to participant.
 *
 * @param {number} participant_record_id
 */
export function sendEmail(participant_record_id) {
  return request({
    url: `api/v1/participants-records/mail/${participant_record_id}`,
    method: "POST",
  });
}

/**
 * Export participant record.
 *
 * @param {object} params
 * @param {string} params.activity_name
 */
export function exportParticipateRecord(params) {
  return request({
    url: `api/v1/participants-records/export`,
    method: "POST",
    params,
    responseType: "blob",
  });
}
